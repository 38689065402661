import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import { vuetify } from '@/plugins/vuetify';
import { i18n } from '@/plugins/i18n';
import LogsPlugin from '@/plugins/logs';
import './styles.css';
import { createPinia } from 'pinia';
import { VueQueryPlugin } from '@tanstack/vue-query';
import { RouteName } from '@/router/routes';
import { useSessionStore } from '@/stores/useSessionStore';

const pinia = createPinia();
const app = createApp(App);

app.use(router);
app.use(pinia);
app.use(VueQueryPlugin);
app.use(vuetify);
app.use(i18n);
app.use(LogsPlugin);

app.mount('#app');

router.beforeEach((to, from, next) => {
  const $session = useSessionStore();
  const isAuthenticated = $session._session.isAuthenticated;
  if (to.meta.requiresAuth && !isAuthenticated) {
    // Redirect unauthenticated users to the login page
    to.path === '/' ? next({ name: RouteName.Login }) : next({ name: RouteName.Login, query: { redirect: to.path } });
    return;
  } else if (to.name === RouteName.Login && isAuthenticated) {
    // Redirect to dashboard if already authenticated
    next({ name: RouteName.Home });
    return;
  } else {
    // Proceed for all other cases
    next();
  }
});

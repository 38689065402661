{
    "contact-details": {
        "email": "E-mail",
        "title": "Détails de contact",
        "location": "Localisation",
        "address": "Adresse",
        "postcode": "Code postal"
    },
    "integration-settings": {
        "title": "Paramètres d'intégration Azure",
        "description": "En saisissant les données, vous activez automatiquement l'intégration Azure, qui comprend la synchronisation de la présence dans MS Teams et la synchronisation des contacts.",
        "label": "Intégration",
        "AzureSettings": "Intégration Azure",
        "azure-client-id": "ID d'application (client)",
        "azure-client-secret": "Valeur du secret client",
        "azure-tenant-id": "ID de répertoire (locataire)",
        "delete-config-msg": "Voulez-vous vraiment supprimer la configuration Azure ?",
        "configuration": "Configuration Azure"
    },
    "invalid-uuid": "La valeur doit être au format UUID",
    "invalid-data": "Données invalides fournies. Veuillez vérifier et réessayer",
    "add": "Ajouter",
    "yes-delete": "Oui, supprimer",
    "add-user": "Ajouter un utilisateur",
    "closewindowdespitecall": "Il y a encore un appel en cours, le fermer quand même ?",
    "admin-portal": "Portail administrateur",
    "internal": "Interne",
    "external": "Externe",
    "inbound": "Entrant",
    "search": "Recherche",
    "unsavedchanges": {
        "validmessage": "Modifications non enregistrées",
        "invalidmessage": "Les modifications non enregistrées ne peuvent pas être enregistrées",
        "valid-content": "Certaines modifications n'ont pas été sauvegardées. Voulez-vous les sauvegarder avant de partir ?",
        "invalid-content": "Il y a des modifications invalides non sauvegardées. Souhaitez-vous les annuler avant de partir ?",
        "content": "Il y a des modifications non enregistrées. Souhaitez-vous les annuler avant de quitter ?",
        "revert": "Supprimer les modifications",
        "cancel": "Adapter les modifications",
        "save": "Enregistrer"
    },
    "loading": "Chargement …",
    "agentspace": "Panneau pour agents",
    "set-agent-status": "Définir le statut de l'agent",
    "choose-agent-status": "Veuillez choisir le statut de l'agent.",
    "set-status": "Définir le statut",
    "agents": "Agents",
    "agent": "Agents",
    "members": "Appelant (file d’attente)",
    "queue": "File d’attente",
    "agent-status": {
        "all": "Tous",
        "in-call": "En conversation",
        "logged-out": "Déconnecté",
        "logged-in": "Connecté",
        "available": "Prêt",
        "break": "En pause",
        "wrap-up": "Post-traitement",
        "inactive": "Inactif"
    },
    "homeview": {
        "no-missed-calls": "Il n'y a pas de nouveaux appels manqués.",
        "up-to-date": "Vous êtes à jour."
    },
    "unknown": "Inconnu",
    "post-processing-time": "Temps de post-traitement",
    "call-reason": "Raison de l’appel",
    "call-history": "Historique de l’appelant",
    "call_history": "Historique d’appel",
    "queues-waiting": "En attente",
    "request_supervisor": "Demander un superviseur",
    "requests_supervisor": "Demande d’un superviseur",
    "supervisor": "Superviseur",
    "referred-by": "Saisi par",
    "diverted-by": "Transmis par",
    "name": "Nom",
    "status": "État",
    "all": "Voir tous",
    "number": "Numéro",
    "position": "Position",
    "waiting-time": "Temps d’attente",
    "time": "Date",
    "steady": "Durée",
    "close": "Fermer",
    "calls": "Appels",
    "replayed": "Répondu",
    "canceled": "Annulé",
    "service-level": "Niveau de service",
    "call-detail": "Détail de l’appel",
    "phone": "Téléphone",
    "offline_message": "Vous êtes déconnecté",
    "break_message": "Vous êtes en pause",
    "no_agent": "Aucun agent ne vous est attribué",
    "forward_to": "Faire suivre à",
    "connect_to": "Transférer",
    "cancel": "Annuler",
    "more": "Plus",
    "merge": "Terminer le transfert",
    "warm": "Chaud",
    "cold": "Froid",
    "hang-up": "Raccrocher",
    "parking": "Parking",
    "warmtransfer": "Transférer",
    "coldtransfer": "Transfert à froid",
    "hold": "Mettre en attente",
    "speaker": "Haut-parleur",
    "unhold": "Reprendre",
    "mute": "Mettre en sourdine",
    "addparticipant": "Ajouter un participant",
    "soundoutputsettings": "Réglages de sortie",
    "participant": "Participant(s)",
    "keypad": "Champ de numérotation",
    "dialpad": "Cadran",
    "endcall": "Quitter",
    "insertnameornumber": "Saisir le nom ou le numéro",
    "group": "Groupe",
    "declined": "Refusé",
    "timeout": "Dépassement de temps",
    "busy": "Occupé",
    "add-contact-number": "Ajouter numéro, contact",
    "conference-member": "Participant(s) à la conférence",
    "back": "Retour",
    "failed": "Non joignable",
    "hung-up": "Raccroché",
    "sound-error": "Problèmes de microphone",
    "save": "Enregistrer",
    "extension": "Poste secondaire",
    "phonelabel": {
        "mobile": "Mobile",
        "fax": "Fax",
        "work": "Travail",
        "other": "Autres"
    },
    "addresslabel": {
        "work": "Travail"
    },
    "video": "Vidéo",
    "work": "Travail",
    "favorites": "Favoris",
    "allContacts": "Tous les contacts",
    "favoriteAdd": "Ajouter aux favoris",
    "favoriteRemove": "Retirer des favoris",
    "importedFrom": "Importé depuis",
    "AssistantTelephoneNumber": "Assistant",
    "BusinessTelephoneNumber": "Professionnel",
    "Business2TelephoneNumber": "Professionnel 2",
    "BusinessFaxNumber": "Fax professionnel",
    "CallbackTelephoneNumber": "Réponse",
    "CarTelephoneNumber": "Voiture",
    "company": "Société",
    "HomeTelephoneNumber": "Privé",
    "Home2TelephoneNumber": "Privé 2",
    "HomeFaxNumber": "Fax privé",
    "ISDNNumber": "ISDN",
    "MobileTelephoneNumber": "Téléphone mobile",
    "OtherFaxNumber": "Autre fax",
    "OtherTelephoneNumber": "Autres",
    "PrimaryTelephoneNumber": "Téléphone principal",
    "RadioTelephoneNumber": "Appel radio",
    "TelexNumber": "Télex",
    "TTYTDDTelephoneNumber": "Téléphone textuel",
    "home": "Accueil",
    "statistics": "Statistiques",
    "hourabbrev": "h",
    "minuteabbrev": "min",
    "secondabbrev": "s",
    "totallogintime": "Délai de connexion total",
    "averagecallduration": "Durée moyenne de la conversation",
    "acceptedcalls": "Conversations acceptées",
    "missedcalls": "Appels manqués",
    "nomissedcalls": "Vous n’avez rien manqué.",
    "languages": "Langues",
    "language": "Langue",
    "logIn": "Se connecter",
    "password": "Mot de passe",
    "stayLoggedIn": "Rester connecté",
    "forgotPassword": "Mot de passe oublié ?",
    "userLogin": "Utilisateur",
    "autologinfailed": "Échec de la connexion automatique",
    "unknownversion": "Version inconnue",
    "userneedsdomain": "Échec : Le nom d’utilisateur doit contenir un domaine.",
    "userhasnoextension": "Échec : L’utilisateur n’a pas attribué de poste secondaire.",
    "wrongcredentials": "Échec : L’utilisateur et le mot de passe ne correspondent pas.",
    "inactiveUser": "Erreur : L'utilisateur n'est pas actif.",
    "serverunreachable": "Échec : Le serveur {0} n’est pas accessible.",
    "checkwtgcloud": "Voulez-vous dire wtg.cloud au lieu de wtg.com ?",
    "unknownerror": "État d’erreur inconnu : {0}",
    "mobiletitel": "Téléphone",
    "history": "Évolution",
    "numpad": "Clavier de sélection",
    "recordings": "Enregistrements",
    "voicemails": "Messages vocaux",
    "historytabs": {
        "all": "Tous",
        "missed": "Manqué",
        "inbound": "Entrant",
        "outbound": "Sortant"
    },
    "enternameornumber": "Saisir le numéro",
    "internalextensionnumber": "Ligne directe",
    "externalextensionnumber": "Mon numéro",
    "request-password-reset-header": "Mot de passe oublié ?",
    "user-at-dot": "Le nom d’utilisateur doit contenir une {'@'} et un .",
    "reset-password": "Réinitialiser le mot de passe",
    "return-to-login": "Retour à la connexion",
    "passwordreset-success-msg": "Un e-mail a été envoyé à l’adresse indiquée afin de réinitialiser le mot de passe. Veuillez vérifier votre boîte de réception.",
    "not-working": "Malheureusement, cela n’a pas fonctionné.",
    "error403": "Vous avez peut-être demandé trop de fois la réinitialisation du mot de passe.",
    "error400": "Veuillez vérifier votre nom d’utilisateur et réessayer.",
    "error-domain": "Veuillez vérifier le domaine (partie derrière le « {'@'} »).",
    "resetsuccesstitle": "Mot de passe réinitialisé",
    "resetsuccessmessage": "Le mot de passe a été réinitialisé.",
    "tologin": "Vers la connexion",
    "resetpassword": "Réinitialiser le mot de passe",
    "newpassword": "Nouveau mot de passe",
    "passwordhastocontain8chars": "Le mot de passe doit comporter au moins 8 caractères.",
    "repeatpassword": "Répéter le mot de passe",
    "passwordsdontmatch": "Les mots de passe ne correspondent pas.",
    "savepassword": "Enregistrer le mot de passe",
    "settings": "Paramètres",
    "user": {
        "deletePhotoConfirmationTitle": "Photo de profil",
        "deletePhotoConfirmationMessage": "Voulez-vous vraiment supprimer la photo ?",
        "userprofile": "Profil",
        "profilepicture": "Photo de profil",
        "newprofilepicture": "Nouvelle photo de profil",
        "deleteprofilepicture": "Supprimer la photo de profil",
        "uploadprofilepicture": "Télécharger",
        "changepassword": "Modifier le mot de passe",
        "oldpassword": "Ancien mot de passe",
        "newpassword": "Nouveau mot de passe",
        "repeatnewpassword": "Répéter le mot de passe",
        "extension": "Poste secondaire",
        "extensionpassword": "Mot de passe du poste secondaire",
        "language": "Langue",
        "passwordwaschanged": "Mot de passe modifié.",
        "useatleast8characters": "Le mot de passe doit comporter au moins 8 caractères.",
        "passwordsdontmatch": "Les mots de passe ne correspondent pas",
        "passwordchangeerror": "Cela n'a pas fonctionné. Veuillez vérifier votre ancien mot de passe et réessayer.",
        "languages": {
            "de": "Allemand",
            "en": "Anglais",
            "fr": "Français",
            "cs": "Tchèque"
        },
        "disablePhone": "Désactiver la téléphonie dans le softclient"
    },
    "callforward": {
        "callforward": "Déviation d’appel",
        "target": "Destination",
        "all": "Déviation de tous les appels",
        "alldescription": "Déviez tous les appels vers la destination indiquée.",
        "busy": "En cas de ligne occupée",
        "busydescription": "La boîte vocale de l'extension sera écrasée.",
        "noanswer": "Aucune réponse",
        "noanswerdescription": "La boîte vocale de l'extension sera écrasée.",
        "notregistered": "Non enregistré",
        "notregistereddescription": "Transfert d’appel vers une nouvelle destination si la destination initiale n’est pas joignable avant l’activation de la messagerie vocale."
    },
    "followme": {
        "deleteTargetConfirmationTitle": "Renvoi d’appel",
        "deleteTargetConfirmationMessage": "Voulez-vous vraiment supprimer la cible ?",
        "followme": "Renvoi d’appel",
        "target": "Destination",
        "delay": "Retard",
        "timeout": "Temps d’éjection",
        "prompt": "Demandes",
        "delete": "Supprimer",
        "prompton": "Entrant",
        "promptoff": "Désactivé",
        "adddestination": "Ajouter",
        "ignorebusy": "Ignorer la ligne occupée",
        "ignorebusydescription": "Interrompre l’appel lorsqu’une destination est occupée."
    },
    "extensionsettings": {
        "extensionsettings": "Paramètres de la ligne",
        "busyonbusydecision": "Plusieurs appels entrants",
        "busyonbusy": "Busy-On-Busy",
        "allowcallwaiting": "Autoriser les appels en attente",
        "secretary-settings": "Paramètres du secrétariat",
        "active": "Actif",
        "secretary": "Secrétariat",
        "exceptions": "Exceptions",
        "callGroups": "Groupes d'appels"
    },
    "audio": {
        "audio": "Audio",
        "inputs": "Entrée",
        "outputs": "Sortie",
        "canteditoutputs": "Les paramètres de sortie audio ne peuvent malheureusement pas être modifiés dans ce navigateur.",
        "ringingoutput": "Sonner la sortie",
        "internalRingtone": "Sonnerie d'appel interne",
        "externalRingtone": "Sonnerie d'appel externe",
        "ringonboth": "Sonner sur les deux appareils",
        "defaultoutput": "Par défaut (les appels en cours ne sont pas réinitialisés)",
        "defaultinput": "Par défaut"
    },
    "notifications": {
        "notifications": "Communications",
        "pickup": "Reprise",
        "banner": "Bannière",
        "banner_info": "Afficher une bannière de notification lors de la réception d’un appel dans le groupe de reprise",
        "sound_status": "Son",
        "sound_status_info": "Lire le son à la réception d’un appel dans le groupe de reprise"
    },
    "information": {
        "information": "Informations client",
        "clientversion": "Version client",
        "showchangelog": "Afficher l’ensemble du journal des changements"
    },
    "callscript": {
        "callscript": "Script d’appel",
        "selectcallscript": "Sélectionner le script d’appel",
        "removecallscript": "Supprimer le script d’appel",
        "testcallscript": "Tester le script d’appel"
    },
    "hotkey": {
        "hotkey": "Raccourci clavier",
        "movetoforeground": "Mettre au premier plan",
        "directcall": "Appeler directement"
    },
    "contacts": {
        "contacts": "Contacts",
        "outlook": "Contacts Outlook",
        "importOutlookContacts": "Importer les contacts Outlook",
        "refreshOutlookContacts": "Actualiser les contacts Outlook",
        "removeOutlookContacts": "Supprimer les contacts Outlook",
        "status": {
            "success": "Contacts importés avec succès.",
            "failure": "Erreur lors de l’importation des contacts.",
            "running": "Importer des contacts …"
        },
        "currentlyImported": "Nombre de contacts importés",
        "outlookExplanation": "Les contacts gérés par Outlook et se trouvant sur le système peuvent être importés. Les contacts importés restent sur le système après l’importation et ne sont pas disponibles sur d’autres appareils."
    },
    "logs": {
        "logs": "Journaux",
        "send-logs": "Envoyer les journaux",
        "delete-logs": "Supprimer les journaux",
        "note": "Ajouter une note aux journaux",
        "disclaimer": "Les journaux ne sont enregistrés que localement et supprimés lorsque le client ou le navigateur est fermé.\n« Envoyer les journaux » permet de les mettre à disposition de WTG.",
        "success": "Des journaux ont été envoyés.",
        "failure": "Erreur lors de l’envoi des journaux.",
        "running": "Envoi des journaux …",
        "deleted": "Les journaux ont été supprimés.",
        "oldest-log": "Journal le plus ancien"
    },
    "revert": "Supprimer les modifications",
    "invalidphonenumber": "Numéro de téléphone non valable.",
    "section_header_second": "Contacts",
    "my_favorites": "Mes favoris",
    "all_contacts": "Tous les contacts",
    "logging_out_message": "Vous êtes déconnecté",
    "logging_in_message": "Vous êtes connecté",
    "inbound-calls": "Appels entrants",
    "parked-calls": "Appels en attente",
    "park_call": "Mettre l’appel en attente",
    "notes": "Notes",
    "add_notes": "Ajouter une note",
    "incomingcall": "Appel entrant",
    "fromconference": "de la conférence avec {0} participants",
    "accept": "Accepter",
    "reject": "Rejeter",
    "statusType": {
        "available": "Disponible",
        "busy": "Occupé",
        "dnd": "Ne pas déranger",
        "away": "De retour dans un instant",
        "hidden": "Afficher comme hors ligne"
    },
    "target": "Destination",
    "logout": "Déconnexion",
    "changelog": "Journal des changements",
    "currentversion": "Version actuelle",
    "previousversion": "Version précédente",
    "queues": "Files d’attente",
    "switchboard": "Central téléphonique",
    "help": "Page d’aide",
    "title": "Mon statut",
    "label-idle": "En pause",
    "label-offline": "Déconnecté",
    "label-active": "Connecté",
    "notLoggedOut": "Toutes les personnes connectées",
    "wait-time": "Temps d’attente",
    "myActiveQueues": "Mes files d’attente actives",
    "noActiveQueues": "Aucune file d’attente active",
    "active": "Actif",
    "onbreak": "En pause",
    "inactive": "Inactif",
    "waitingMemberCount": "File d’attente",
    "holding": "Mettre en attente",
    "dialing": "Sélectionner",
    "user-cant-be-edited": "L’utilisateur ne peut pas être modifié ici",
    "contact-wtg": "Veuillez vous adresser à WTG.",
    "error": {
        "TOO_MANY_CONTACTS": "Trop de contacts attribués",
        "TOO_MANY_EXTENSIONS": "Trop de postes secondaires attribués",
        "TOO_MANY_VOICEMAILS": "Trop de messageries vocales attribuées",
        "TOO_MANY_DESTINATIONS": "Trop de numéros de téléphone entrants",
        "wronglink": "Ce lien de réinitialisation du mot de passe est erroné. Veuillez fermer cette page et cliquer une nouvelle fois sur le lien figurant dans votre e-mail.",
        "tokeninvalid": "Votre mot de passe a malheureusement expiré. Veuillez demander un nouveau mot de passe en utilisant la fonction d’oubli du mot de passe.",
        "unknown": "Malheureusement, cela n’a pas fonctionné, veuillez réessayer."
    },
    "newuser": "Nouvel utilisateur",
    "errorKey": "Erreur",
    "enabled": "Actif",
    "profile-data": "Données du profil",
    "licence": "Licence",
    "firstname": "Prénom",
    "lastname": "Nom",
    "organization": "Entreprise",
    "username": "Nom d’utilisateur",
    "userpassword": "Nouveau mot de passe",
    "repeatuserpassword": "Répéter le mot de passe",
    "email-address": "Adresse e-mail",
    "extensionnumber": "Numéro de poste secondaire",
    "will-be-set-on-save": "Défini au hasard lors de l’enregistrement",
    "externalnumbers": "Numéros de téléphone externes",
    "external-inbound-phone-number": "Entrant",
    "external-outbound-phone-number": "Sortant",
    "voicemail-settings": "Messagerie vocale",
    "voicemail-active": "Messagerie vocale activée",
    "voicemail-password": "Mot de passe de la messagerie vocale",
    "voicemail-language": "Langue de la messagerie vocale",
    "call-group-settings": "Groupes de reprise",
    "call-group": {
        "active": "Actif",
        "groups": "Groupe",
        "enter-groups": "Saisissez ici les groupes de reprise de l’utilisateur.",
        "deleteGroupConfirmationTitle": "Groupe",
        "deleteGroupConfirmationMessage": "Voulez-vous vraiment supprimer le groupe \"{name}\" ?",
        "not-assigned": "Vous n'êtes actuellement affecté à aucun groupe d'appels",
        "confirm": "Confirmer",
        "no-confirmation": "Pas de confirmation"
    },
    "call-forward-settings": "Transfert d’appel",
    "phone-number": "Déviation d’appel",
    "at-busy": "En cas de ligne occupée",
    "no-answer": "Aucune réponse",
    "not-registered": "Non enregistré",
    "call-forwarding": {
        "phone-number": "Déviez tous les appels vers la destination indiquée.",
        "at-busy": "Si ce paramètre est activé, la messagerie vocale activée du poste secondaire est remplacée.",
        "no-answer": "Si ce paramètre est activé, la messagerie vocale activée est remplacée.",
        "not-registered": "Transfert d’appel vers une nouvelle destination si la destination initiale n’est pas joignable avant l’activation de la messagerie vocale."
    },
    "restriction-errors": {
        "too-short": "Doit contenir au moins {minimum} caractères",
        "regex-errors": {
            "PHONE_NUMBER": "Doit être un numéro de téléphone et commencer par +",
            "EXTENSION_NUMBER": "Ne peut contenir que des chiffres et ne peut pas commencer avec un 0",
            "EMAIL": "Adresse e-mail non valide",
            "DOMAIN_NUMBER_RESTRICTION": "Ce numéro de téléphone n’est pas autorisé pour votre compte",
            "VOICEMAIL_PASSWORD": "Doit comporter 6 à 16 chiffres",
            "USERNAME": "Le nom d’utilisateur doit être composé de lettres, de chiffres, de « - », « _ » ou « . ».",
            "other": "Mauvais format"
        },
        "excluded-value-list-errors": {
            "CALL_CENTER_QUEUE": "Ce numéro est déjà utilisé pour une file d’attente du centre d’appels",
            "RING_GROUP": "Ce numéro est déjà utilisé pour un groupe d’appels",
            "TIME_CONDITION": "Ce numéro est déjà utilisé pour une condition de temps",
            "DESTINATION": "Ce numéro est déjà utilisé pour une destination",
            "IVR_MENU": "Ce numéro est déjà utilisé pour un menu IVR",
            "other": "Ce numéro est déjà utilisé ailleurs"
        },
        "wrong-format": "Mauvais format",
        "empty": "Ne doit pas être vide",
        "passwords-not-matching": "Les mots de passe doivent correspondre",
        "duplicate": "Cette valeur est déjà attribuée à l’utilisateur {user}",
        "at-least-one-target": "Au moins une option cible doit être ajoutée",
        "unknown-error": "Erreur de validation"
    },
    "secretary-function-settings": "Paramètres du secrétariat",
    "secretary-function": {
        "active": "Actif",
        "target": "Mon secrétariat"
    },
    "ringduration": "{seconds} s de sonnerie",
    "callduration": "{seconds} s de temps d’appel",
    "missedcall": "Manqué",
    "contactinfo": "Coordonnées",
    "enter-new-message": "Saisir un nouveau message",
    "unknown-contact": "Contact inconnu",
    "invite": "Inviter des interlocuteurs",
    "fromconferenceOptions": "de la conférence sans participants | de la conférence avec un participant | de la conférence avec {n} participants",
    "wtgcloudpure": "WTG Cloud PURE",
    "incomingcallfrom": "Appel entrant de {0}",
    "incomingcalls": "Aucun appel entrant | Appel entrant | {n} appels entrants",
    "pickup": "Reprise",
    "hide": "Masquer",
    "show_all": "Tout afficher",
    "search-for-user": "Recherche d’un utilisateur",
    "clear-selection": "Vider la sélection",
    "not-available": "Aucun numéro disponible",
    "min": "min",
    "sec": "sec.",
    "today": "Aujourd’hui",
    "yesterday": "Hier",
    "break-comment": {
        "break-comment": "Motif de la pause",
        "select-prompt": "Choisir le motif de la pause",
        "MEETING": "Conversation",
        "BACKOFFICE": "Backoffice",
        "BREAK": "Pause",
        "SUPERVISOR": "Superviseur"
    },
    "answered": "Répondu",
    "logged-out": "Déconnecté",
    "available": "Disponible",
    "take-a-break": "Faire une pause",
    "choose-break-reason": "Veuillez choisir la raison de votre pause.",
    "select-break-reason": "Sélectionner le motif de la rupture",
    "break": "Pause",
    "break-reason": "Motif de la pause",
    "in-call": "En conversation",
    "wrap-up": "Post-traitement",
    "backdrop_you_are_set_to_break": "Vous avez été mis sur « Pause ».",
    "backdrop_supervisor_set_break": "Votre superviseur a passé votre statut sur « Pause ». Vous n’êtes pas actif et ne recevez plus aucun appel de la file d’attente. Le motif de la pause est le suivant : Défini sur « Pause » par le superviseur.",
    "backdrop_inactive_set_break": "Comme vous n’avez plus accepté d’appel, votre statut a été défini sur « Pause ». Vous n’êtes pas actif et ne recevez plus aucun appel de la file d’attente. Le motif de la pause est le suivant : Défini sur « Pause » en raison de l’inactivité.",
    "backdrop_return_to_active": "Pour être à nouveau actif, vous devez modifier votre statut sur « Connecté ».",
    "park": "Mettre en attente",
    "park-call": "Mettre l’appel en attente",
    "search-name": "Recherche",
    "edit": "Modifier",
    "done": "Prêt",
    "all-read": "Tous lus",
    "delete": "Supprimer",
    "no-one-waiting": "Aucune attente.",
    "everyone-happy": "Tout le monde est satisfait.",
    "call-reasons": {
        "no-reason": "Aucun motif",
        "customer-data-changed": "Modification des données client",
        "opening-time-inquiry": "Demande des heures d’ouverture",
        "banking-information": "Coordonnées bancaires",
        "order": "Commande",
        "product-inquiry": "Demande de produit",
        "support": "Assistance"
    },
    "duration": "Durée",
    "groupname": "Nom de groupe",
    "to": "À",
    "search-for-contact": "Rechercher contact",
    "direct": "Direct",
    "participants": "Participant(s)",
    "contact": "Contact",
    "no-number-available": "Aucun numéro disponible",
    "type": "Type",
    "calltime": "Heure d’appel",
    "date": "Date",
    "notice": "Notes",
    "waittime": "Temps d’attente",
    "Overview": "Aperçu",
    "Users": "Utilisateur",
    "call-settings": {
        "label": "Paramètres téléphoniques",
        "RingGroups": "Groupes d’appels collectifs",
        "CallBroadcast": "Diffusion d’appels",
        "Blacklisting": "Mise sur liste noire",
        "IVR": "IVR",
        "AnnouncementManagement": "Gestion des annonces",
        "OpenTimes": "Jours fériés / Heures d’ouverture",
        "ParkingFunction": "Fonction « Mise en attente »",
        "Pickup": "Reprise",
        "SwitchBoardSettings": "Poste de transfert"
    },
    "devices-settings": {
        "label": "Appareils terminaux",
        "Provisoning": "Commissionnement",
        "Registration": "Enregistrement"
    },
    "callcenter-settings": {
        "label": "Paramètres du centre d’appels",
        "QueuesSettings": "Files d’attente du centre d’appels",
        "Supervisor": "Superviseur du centre d’appels"
    },
    "softclient-settings": {
        "label": "Paramètres du client logiciel",
        "Shortcuts": "Raccourcis clavier",
        "MultiField": "Champs multiples"
    },
    "ivr": {
        "ivr": "IVR",
        "IVRs": "Menu IVR",
        "addIVR": "Ajouter un IVR",
        "editIVR": "Éditer un IVR",
        "deleteIVR": "Supprimer un IVR",
        "cancel": "Annuler",
        "search": "Recherche pour IVR",
        "save": "Enregistrer",
        "active": "Actif",
        "name": "Nom",
        "addName": "Ajouter un nom",
        "extension": "Extension",
        "addExtension": "Ajouter une extension",
        "parentMenu": "Menu parent",
        "selectParentMenu": "Sélectionner le menu parent",
        "language": "Langue",
        "selectLanguage": "Sélectionner la langue",
        "description": "Description",
        "addDescription": "Ajouter une description",
        "greeting": "Salutation",
        "selectAnnouncement": "Sélectionner la salutation",
        "long": "Long",
        "longGreetingDescription": "La salutation longue est jouée lors de l'entrée dans le menu.",
        "short": "Court",
        "shortGreetingDescription": "La salutation courte est jouée lors du retour au menu.",
        "options": "Options",
        "option": "Option",
        "target": "Cible",
        "selectTarget": "Sélectionner la cible",
        "order": "Ordre",
        "defineCallOptions": "Définir les options d'appel pour le menu IVR.",
        "timeout": "Délai",
        "time": "Temps",
        "enterTime": "Définir le délai",
        "selectAction": "Sélectionner une action",
        "enterTimeFieldDescription": "Le nombre de millisecondes à attendre après la diffusion de la salutation ou de la macro de confirmation.",
        "exitAction": "Action de sortie",
        "selectExitActionFieldDescription": "Sélectionner l'action de sortie à effectuer si l'IVR quitte.",
        "ringback": "Son de retour",
        "ringbackFieldDescription": "Définit ce que l'appelant entendra pendant que la destination est appelée.",
        "callerPrefix": "Préfixe de l'appelant",
        "callerPrefixFieldDescription": "Définir un préfixe sur le nom de l'appelant.",
        "selectPrefix": "Sélectionner le préfixe",
        "selectSound": "Sélectionner le son",
        "invalidOptionSound": "Son d'option invalide",
        "invalidOptionSoundFieldDescription": "Joué lorsque qu'une option invalide est choisie.",
        "soundOnMenuExit": "Son lors de la sortie du menu",
        "soundOnMenuExitFieldDescription": "Joué lors de la sortie du menu.",
        "pin": "PIN",
        "setPin": "Définir un PIN",
        "pinFieldDescription": "Numéro PIN facultatif pour sécuriser l'accès au menu.",
        "delayTimeBetweenInput": "Délai entre les saisies",
        "setDelayTimeBetweenInput": "Définir le délai entre les saisies",
        "delayTimeBetweenInputFieldDescription": "Le nombre de millisecondes à attendre entre les chiffres.",
        "maxFailures": "Échecs maximaux",
        "setMaxFailures": "Définir les échecs max.",
        "maxFailuresFieldDescription": "Nombre maximal de tentatives avant la sortie.",
        "maximumTimeouts": "Délais max.",
        "setMaximumTimeouts": "Définir les délais max.",
        "maximumTimeoutsFieldDescription": "Nombre maximal de délais avant la sortie.",
        "digitLength": "Longueur des chiffres",
        "setDigitLength": "Définir la longueur des chiffres",
        "digitLengthFieldDescription": "Nombre maximal de chiffres autorisés.",
        "recordingAnnouncement": "Les annonces doivent être ajoutées via le WTG Master Admin. Si vous souhaitez ajouter de nouveaux fichiers audio, veuillez les envoyer à : pure.tsc{'@'}wtg.com",
        "deleteIvrConfirmationTitle": "IVR Menu",
        "deleteIvrConfirmationMessage": "Voulez-vous vraiment supprimer l'IVR \"{name}\" ?"
    },
    "pickupGroups": {
        "search": "Recherche de groupes de ramassage",
        "members-number": "Nombre de membres du groupe",
        "addPickupGroup": "Ajouter un groupe de Pickup",
        "editPickupGroup": "Modifier le groupe de Pickup",
        "pickupGroupsHeader": "Groupes de Pickup",
        "numberOfMembers": "Nombre de membres",
        "members": "Membres",
        "notBlank": "L'entrée ne doit pas être vide !",
        "noMembers": "La liste des membres ne doit pas être vide !",
        "nameTaken": "Le nom est déjà utilisé !",
        "deleteGroupConfirmationTitle": "Groupes de Pickup",
        "deleteGroupConfirmationMessage": "Voulez-vous vraiment supprimer le groupe de ramassage \"{name}\" ?"
    },
    "call-center-queues": {
        "logged-queues": "Files d'attente",
        "search": "Recherche de files d'attente pour les centres d'appels",
        "queues": "Files d'attente",
        "call-center-queues": "Files d'attente des centres d'appel",
        "cancel": "Supprimer les modifications",
        "save": "Enregistrer",
        "addQueue": "Ajouter une file d'attente",
        "editQueue": "Editer la file d'attente",
        "deleteQueue": "Supprimer la file d'attente",
        "name": "Nom",
        "addName": "Ajouter un nom",
        "extension": "Extension",
        "selectExtension": "Sélectionner l'extension",
        "greeting": "Message d'accueil",
        "selectGreeting": "Sélectionner le message d'accueil",
        "strategy": "Stratégie",
        "selectStrategy": "Sélectionner une stratégie",
        "description": "Description",
        "addDescription": "Ajouter une description",
        "thresholds": "Seuil",
        "value": "Valeur",
        "redStart": "Rouge de",
        "orangeStart": "Orange de",
        "numberOfWaiting": "Nombre d'appelants en attente",
        "loggedLessThan": "Connecté moins de (en %)",
        "inBreakMoreThan": "En pause plus de (en %)",
        "availableLessThan": "Disponible moins de (en %)",
        "thresholdsSectionDescription": "Définir des seuils pour une alerte visuelle.",
        "agents": "Agents",
        "tierLevel": "Niveau",
        "tierPosition": "Position de niveau",
        "addAgent": "Sélectionner l'agent",
        "level": "Niveau",
        "pos": "Position",
        "agentsSectionDescription": "Les niveaux affectent les agents aux files d'attente.",
        "supervisor": "Superviseur",
        "addSupervisor": "Sélectionner le superviseur",
        "supervisorSectionDescription": "Sélectionnez un ou plusieurs superviseurs pour cette file d'attente.",
        "musicOnHold": "Musique d'attente",
        "selectMusicOnHold": "Sélectionner la musique d'attente",
        "order": "Commande",
        "selectOrder": "Sélectionner la commande",
        "recording": "Enregistrer",
        "recordingToggleDescription": "Sauvegarder l'enregistrement.",
        "timeBaseValue": "Score de base de temps",
        "timeBaseValueFieldDescription": "Permet de déterminer si le temps passé par l'appelant dans le système est compté comme temps d'attente.",
        "timeout": "Temps d’éjection",
        "selectWaitingTime": "Définir le temps d'attente maximum",
        "maxWaitingTime": "Temps d'attente maximum",
        "maxWaitingTimeFieldDescription": "Temps d'attente en secondes avant l'exécution de l'action.",
        "maxWaitingTimeNoAgent": "Temps d'attente maximum sans agent",
        "maxWaitingTimeNoAgentFieldDescription": "Le temps qu'un appelant passe dans la file d'attente avant que l'action sélectionnée ne soit programmée.",
        "maxWaitingTimeNoAvailableAgent": "Temps d'attente maximal sans agent disponible",
        "maxWaitingTimeNoAvailableAgentFieldDescription": "Le temps qu'un appelant passe dans la file d'attente avant que l'action sélectionnée ne soit programmée.",
        "timeoutAction": "Action de temps d’éjection",
        "timeoutActionFieldDescription": "Définir l'action à effectuer lorsque le temps d'attente maximum est atteint.",
        "selectAction": "Sélectionner l'action de temps d’éjection",
        "tierRules": "Règles de niveau",
        "tierRulesToggleDescription": "Indiquer si les règles de niveau doivent s'appliquer à la file d'attente.",
        "tierRuleWaitMultiply": "Prolongation du délai d'attente de la règle de l'échelon",
        "tierRuleWaitMultiplyToggleDescription": "Prolongation du temps d'attente si l'appelant attend le niveau suivant.",
        "tierRuleNoAgentNoWait": "Pas d'agent, pas de temps d'attente",
        "tierRuleNoAgentNoWaitToggleDescription": "Si aucun agent n'est disponible, l'appelant passe directement au niveau suivant.",
        "discardCall": "Abandonner l'appelant",
        "discardCallFieldDescription": "Spécifiez après combien de secondes l'appel doit être rejeté.",
        "selectTime": "Sélectionner le temps",
        "abandonedResumeAllowed": "Reprendre l'appelant abandonné",
        "abandonedResumeAllowedToggleDescription": "Permettre à l'appelant de reprendre sa place dans la file d'attente.",
        "callerPrefix": "Préfixe de l'appelant",
        "setPrefix": "Définir le préfixe",
        "callerPrefixFieldDescription": "Spécifiez un préfixe qui doit s'afficher devant le numéro de téléphone pour les appelants.",
        "announceSound": "Son d'annonce",
        "announceSoundFieldDescription": "Le message d'attente à diffuser à l'appelant à intervalles réguliers.",
        "selectSound": "Sélectionner le son",
        "announceFrequency": "Fréquence d'annonce",
        "announceFrequencyFieldDescription": "Après combien de secondes le message d'attente doit être diffusé de manière répétée.",
        "ring-all": "Sonnerie générale",
        "longest-idle-agent": "L'agent en pause le plus longtemps",
        "round-robin": "Tour par tour",
        "top-down": "De haut en bas",
        "agent-with-least-talk-time": "Agent avec le temps de parole le plus court",
        "agent-with-fewest-calls": "Agent avec le moins d'appels",
        "sequentially-by-agent-order": "Séquentiellement par ordre des agents",
        "sequentially-by-next-agent-order": "Séquentiellement par ordre du prochain agent",
        "random": "Au hasard",
        "deleteQueueConfirmationTitle": "Files d'attente des centres d'appels",
        "deleteQueueConfirmationMessage": "Voulez-vous vraiment supprimer la file d'attente \"{name}\" ?",
        "addQueueSuccess": "L'ajout de la file d'attente a été effectué avec succès.",
        "addQueueError": "Un problème est survenu lors de l'ajout de la file d'attente",
        "editQueueSuccess": "La file d'attente a été modifiée avec succès.",
        "editQueueError": "Un problème est survenu lors de l'édition de la file d'attente",
        "removeQueueSuccess": "La file d'attente a été supprimée avec succès.",
        "removeQueueError": "Un problème est survenu lors de la suppression de la file d'attente",
        "callerOutboundName": "Nom du destinataire",
        "callerOutboundNameDescription": "Entrez le nom de l'appelant externe.",
        "callerOutboundNumber": "Numéro sortant",
        "callerOutboundNumberDescription": "Entrez le numéro de sortie de l'appelant externe."
    },
    "ring-groups": {
        "search": "Recherche de groupes d'appel",
        "active": "Actif",
        "callGroups": "Groupes d'appel collectif",
        "add": "Ajouter",
        "deleteCallGroup": "Supprimer le groupe",
        "name": "Nom",
        "addName": "Ajouter un nom",
        "groups": "Groupes",
        "extension": "Poste",
        "addExtension": "Sélectionner une extension",
        "greeting": "Salutation",
        "selectGreeting": "Choisir une salutation",
        "strategy": "Stratégie",
        "selectStrategy": "Choisir une stratégie",
        "description": "Description",
        "addDescription": "Ajouter une description",
        "save": "Enregistrer",
        "cancel": "Annuler",
        "addCallGroup": "Ajouter un groupe",
        "editCallGroup": "Groupe Modifier",
        "delay": "Retarder",
        "timeLimit": "Limite de temps",
        "accept": "Accepter",
        "targetByTimeLimit": "Cible par limite de temps",
        "selectTarget": "Sélectionner la cible",
        "addTarget": "Ajouter une cible",
        "targetsSectionDescription": "Ajoutez des objectifs et des paramètres au groupe d'anneaux.",
        "callTimeout": "Délai d'appel",
        "chooseCallTimeout": "Choisir un délai d'appel",
        "callerIdName": "Nom de l'appelant",
        "selectCallerIdName": "ID de l'appelant Choisir le nom",
        "callerIdNameFieldDescription": "Définir le nom de l'ID de l'appelant pour les appels externes sortants.",
        "callerIdNumber": "Numéro de l'appelant",
        "selectCallerIdNumber": "Choisir une ID d'appelant",
        "callerIdNumberFieldDescription": "Définir le numéro d'identification de l'appelant pour les appels externes sortants.",
        "busyTone": "Tonalité d'occupation",
        "busyToneFieldDescription": "Définissez ce que l'appelant entend pendant que la cible est appelée.",
        "selectSound": "Choisir une sonnerie",
        "recall": "Rappel",
        "recallFieldDescription": "Spécifie ce que l'appelant entend pendant que la cible est appelée.",
        "groupMembers": "Membres du groupe",
        "selectContact": "Sélectionner un contact",
        "forwarding": "Transfert",
        "forwardingFieldDescription": "Choisissez si le renvoi d'appel doit être appliqué au sein du groupe.",
        "followMe": "Suivez-moi",
        "followMeFieldDescription": "Choisissez l'option \"Suivez-moi\" pour une cible de groupe de sonnerie.",
        "missedCalls": "Appels manqués",
        "missedCallsFieldDescription": "Choisissez si quelqu'un doit être averti par e-mail.",
        "emailAddress": "Adresse e-mail",
        "target": "Cible",
        "targets": "Cibles",
        "typeOfNotification": "Type de notification",
        "callGroupForwarding": "Transfert de groupes d'appels collectifs",
        "callGroupForwardingFieldDescription": "Transfert d'un groupe d'appel appelé vers une autre destination.",
        "selectNumber": "Sélectionner un numéro",
        "feesForwarding": "Transfert de frais d'appel",
        "selectFeesForwarding": "Sélectionner transfert de frais d'appel",
        "callGroupForward": "Transférer un groupe d'appel collectif",
        "callGroupForwardFieldDescription": "Déviation de l'ensemble du groupe d'appel collectif vers une destination sélectionnée.",
        "simultaneous": "Simultané",
        "sequence": "Séquence",
        "enterprise": "Entreprise",
        "rollover": "Renversement",
        "random": "Aléatoire"
    },
    "no": "Non"
}

import type { Presence, Uuid } from '@/data/Types';
import type { TranslateResult } from 'vue-i18n';

export type AgentCallAction = 'CALL' | 'FORWARD' | 'NONE';
export type ExtendedAgent = Agent & { presence?: Presence; callAction: AgentCallAction };

export enum AgentStatus {
  LOGGED_OUT = 'Logged Out',
  AVAILABLE = 'Available',
  AVAILABLE_ON_DEMAND = 'Available (On Demand)',
  ON_BREAK = 'On Break',
}

export enum AgentState {
  IDLE = 'Idle',
  WAITING = 'Waiting',
  RECEIVING = 'Receiving',
  IN_CALL = 'In a queue call',
}

export interface AgentDTO {
  agent_name: string;
  agent_uuid: string;
  downtime_begin: number | null;
  downtime_end: number | null;
  downtime_comment: string | null;
  downtime_reason: 'MANUAL_BREAK' | 'WRAP_UP' | 'NO_ANSWER' | null;
  state: AgentState;
  status: AgentStatus;
  user_uuid: string;
}

export interface Agent {
  uuid: Uuid;
  userUuid: Uuid;
  name: string;
  status: AgentStatus;
  state: AgentState;
  downtime_begin: number | null;
  downtime_end: number | null;
  downtime_reason: 'MANUAL_BREAK' | 'WRAP_UP' | 'NO_ANSWER' | null;
  downtime_comment: string | null;
}

export enum AgentDisplayStatus {
  AVAILABLE = AgentStatus.AVAILABLE,
  ON_BREAK = AgentStatus.ON_BREAK,
  LOGGED_OUT = AgentStatus.LOGGED_OUT,
  IN_CALL = AgentState.IN_CALL,
}

export type AgentStateOption = {
  value: AgentDisplayStatus;
  label: TranslateResult;
  identifier: string;
};

import { buildFormData, httpGet, httpPost } from '@/api/http';
import { CALL_CENTER } from '@/constants/endpoint';
import { getCurrentTime } from '@/util';
import { ref } from 'vue';
import { useAuthenticatedQuery } from '@/utils/query';
import { useMutation, useQueryClient } from '@tanstack/vue-query';
import { type Agent, type AgentDTO, AgentStatus } from '@/data/Agent';
import { normalizeArrayToMapByKey } from '@/utils/dataTransformationUtils';

const AGENTS_QUERY_KEY = 'agents';

export const useAgents = (refetchEnabled = ref(false)) => {
  const queryClient = useQueryClient();

  const {
    data: _agents,
    refetch: refetchAgents,
    status,
  } = useAuthenticatedQuery({
    queryKey: [AGENTS_QUERY_KEY],
    queryFn: () =>
      httpGet<{ agents: AgentDTO[] }>(CALL_CENTER, { params: { get: 'agents' } })
        .then((r) => r.agents)
        .then((r) => r.map(mapToAgent))
        .then((agents) => normalizeArrayToMapByKey(agents, 'uuid')),
    refetchInterval: 3_500,
    enabled: refetchEnabled,
  });

  // TODO: Change in the future to optimistic update
  // setTimeout to 1_100 since we update the DB(Agents) every one second
  // https://confluence.wtg-innovation.com/display/WCP/Managing+Services+on+the+Server
  type AgentStatusData = { status: AgentStatus } | { status: AgentStatus; comment: string };
  const setAgentStatus = (agentUuid: string, status: AgentStatus, comment?: string | null) => {
    const data: AgentStatusData =
      comment == null
        ? { status }
        : {
            status: status,
            comment: comment,
          };
    return httpPost(CALL_CENTER, buildFormData(data), {
      params: {
        action: 'set_status',
        agent_uuid: agentUuid,
      },
    }).then(() => setTimeout(() => refetchAgents(), 1_100));
  };

  const logoutAgent = (agentUuid: string) => {
    const data = { status: AgentStatus.LOGGED_OUT };
    return httpPost(CALL_CENTER, buildFormData(data), { params: { action: 'set_status', agent_uuid: agentUuid } });
  };

  const editDowntimeMutation = useMutation({
    mutationFn: ({ agentUuid, delta }: { agentUuid: string; delta: number }) => {
      const data = buildFormData({ delta: delta.toString() });
      return httpPost(CALL_CENTER, data, { params: { action: 'edit_downtime', agent_uuid: agentUuid } });
    },
    onMutate: async ({ agentUuid, delta }: { agentUuid: string; delta: number }) => {
      await queryClient.cancelQueries({ queryKey: [AGENTS_QUERY_KEY] });

      const previousData = queryClient.getQueryData([AGENTS_QUERY_KEY]);

      queryClient.setQueryData([AGENTS_QUERY_KEY], (agents: Map<string, Agent>) => {
        const updatedAgents = new Map(agents);

        const agentToUpdate = updatedAgents.get(agentUuid);

        if (agentToUpdate && agentToUpdate.downtime_end) {
          const updatedAgent = {
            ...agentToUpdate,
            downtime_end: agentToUpdate.downtime_end + delta,
          };

          updatedAgents.set(agentUuid, updatedAgent);
        }

        return updatedAgents;
      });
      return { previousData };
    },
    onSettled: () => {
      setTimeout(() => refetchAgents(), 1_100);
    },
    onError: (err, agentData, context) => {
      queryClient.setQueryData([AGENTS_QUERY_KEY], context?.previousData);
    },
  });

  const editDowntime = ({ agentUuid, delta }: { agentUuid: string; delta: number }) => {
    editDowntimeMutation.mutate({ agentUuid, delta });
  };

  const endDowntime = (agentUuid: string) => {
    httpPost(CALL_CENTER, null, {
      params: {
        action: 'end_downtime',
        agent_uuid: agentUuid,
      },
    }).then((_) => setTimeout(() => refetchAgents(), 1_100));
  };

  return { _agents, setAgentStatus, refetchAgents, editDowntime, endDowntime, status, logoutAgent };
};

export const waitingTimeLeft = (agent: Agent): number | null => {
  if (agent.downtime_reason !== 'WRAP_UP' || !agent.downtime_end) return null;
  const now = getCurrentTime(250) / 1000;
  return agent.downtime_end >= now ? agent.downtime_end - now : null;
};

const mapToAgent = (agentDTO: AgentDTO): Agent => ({
  uuid: agentDTO.agent_uuid,
  userUuid: agentDTO.user_uuid,
  name: agentDTO.agent_name,
  status: agentDTO.status,
  state: agentDTO.state,
  downtime_begin: agentDTO.downtime_begin,
  downtime_end: agentDTO.downtime_end,
  downtime_reason: agentDTO.downtime_reason,
  downtime_comment: agentDTO.downtime_comment,
});

import { type RouteRecordRaw } from 'vue-router';

export const RouteName = {
  Login: 'Login',
  Home: 'Home',
  Phone: 'Phone',
  Toast: 'Toast',
  Call: 'Call',
  ProfileSettings: 'ProfileSettings',
  CallForwardSettings: 'CallForwardSettings',
  FollowMeSettings: 'FollowMeSettings',
  ExtensionSettings: 'ExtensionSettings',
  VoicemailSettings: 'VoicemailSettings',
  AudioSettings: 'AudioSettings',
  NotificationSettings: 'NotificationSettings',
  ClientInformationSettings: 'ClientInformationSettings',
  CallScriptSettings: 'CallScriptSettings',
  ContactsSettings: 'ContactsSettings',
  HotkeysSettings: 'HotkeysSettings',
  LogsSettings: 'LogsSettings',
  Pickup: 'Pickup',
  PasswordResetRequest: 'RequestPasswordReset',
  PasswordReset: 'PasswordReset',
};

const routes: Readonly<RouteRecordRaw[]> = [
  {
    path: '/toast',
    name: RouteName.Toast,
    component: () => import('@/views/Toast.vue'),
    meta: { requiresAuth: false, electronOnly: true },
  },
  {
    path: '/reset_password/',
    name: RouteName.PasswordReset,
    component: () => import('@/views/PasswordReset.vue'),
  },
  {
    path: '/public',
    component: () => import('@/layouts/PublicLayout.vue'),
    children: [
      {
        path: 'login',
        name: RouteName.Login,
        component: () => import('@/views/Login.vue'),
      },
      {
        path: 'requestpasswordreset',
        name: RouteName.PasswordResetRequest,
        component: () => import('@/views/RequestPasswordReset.vue'),
      },
    ],
  },
  {
    path: '/',
    component: () => import('@/layouts/AuthenticatedLayout.vue'), // General authenticated layout
    meta: { requiresAuth: true },
    children: [
      {
        path: '/client',
        component: () => import('@/layouts/ClientLayout.vue'),
        children: [
          {
            path: 'home',
            name: RouteName.Home,
            component: () => import('@/views/HomeView.vue'),
          },
          {
            path: 'phone',
            name: RouteName.Phone,
            component: () => import('@/views/PhoneView.vue'),
          },
          {
            path: 'contacts',
            name: 'Contacts',
            component: () => import('@/views/ContactsView.vue'),
          },
          {
            path: 'call/:callid',
            name: RouteName.Call,
            component: () => import('@/views/Call.vue'),
          },
          {
            path: 'queues',
            name: 'Queues',
            component: () => import('@/views/AgentQueuesView.vue'),
          },
          {
            path: 'switchboard',
            name: 'SwitchBoard',
            component: () => import('@/views/SwitchBoardView.vue'),
          },
        ],
      },
      {
        path: '/admin-portal/main',
        name: 'Overview',
        component: () => import('@/views/AdminPortal.vue'),
        children: [
          {
            path: 'cs',
            name: 'CallSettings',
            redirect: 'admin-portal/main',
            component: () => import('@/components/admin-portal/call-settings/CallSettings.vue'),
            children: [
              {
                path: 'ccg',
                name: 'RingGroups',
                component: () => import('@/components/admin-portal/call-settings/CallGroups.vue'),
              },
              {
                path: 'ivr',
                name: 'IVR',
                component: () => import('@/components/admin-portal/call-settings/IVR.vue'),
              },
              {
                path: 'pu',
                name: 'Pickup',
                component: () => import('@/views/PickupView.vue'),
              },
            ],
          },
          {
            path: 'cc',
            name: 'CallCenter',
            redirect: 'admin-portal/main',
            component: () => import('@/components/admin-portal/call-center-queues/CallCenterSettings.vue'),
            children: [
              {
                path: 'q',
                name: 'QueuesSettings',
                component: () => import('@/components/admin-portal/call-center-queues/CallCenterQueues.vue'),
              },
            ],
          },
          {
            path: 'in',
            name: 'Integration',
            redirect: 'admin-portal/main',
            component: () => import('@/components/admin-portal/integration/IntegrationSettings.vue'),
            children: [
              {
                path: 'as',
                name: 'AzureSettings',
                component: () => import('@/components/admin-portal/integration/AzureIntegration.vue'),
              },
            ],
          },
        ],
      },
      {
        path: '/admin-portal/users',
        name: 'Users',
        component: () => import('@/views/UserAdministration.vue'),
        children: [
          {
            path: ':useruuid',
            name: 'User',
            component: {},
          },
          {
            path: 'import',
            name: 'ImportUsers',
            component: {},
          },
        ],
      },
      {
        path: '/settings',
        name: 'Settings',
        component: () => import('@/views/Setting.vue'),
        children: [
          {
            path: 'profile',
            name: RouteName.ProfileSettings,
            component: () => import('@/views/settings/ProfileSettingsView.vue'),
          },
          {
            path: 'call-forward',
            name: RouteName.CallForwardSettings,
            component: () => import('@/views/settings/CallForwardSettingsView.vue'),
          },
          {
            path: 'follow-me',
            name: RouteName.FollowMeSettings,
            component: () => import('@/views/settings/FollowMeSettingsView.vue'),
          },
          {
            path: 'extension',
            name: RouteName.ExtensionSettings,
            component: () => import('@/views/settings/ExtensionSettingsView.vue'),
          },
          {
            path: 'voicemail',
            name: RouteName.VoicemailSettings,
            component: () => import('@/views/settings/VoicemailSettingsView.vue'),
          },
          {
            path: 'audio',
            name: RouteName.AudioSettings,
            component: () => import('@/views/settings/AudioSettingsView.vue'),
          },
          {
            path: 'notification',
            name: RouteName.NotificationSettings,
            component: () => import('@/views/settings/NotificationSettingsView.vue'),
          },
          {
            path: 'client-information',
            name: RouteName.ClientInformationSettings,
            component: () => import('@/views/settings/ClientInformationView.vue'),
          },
          {
            path: 'call-scripts',
            name: RouteName.CallScriptSettings,
            component: () => import('@/views/settings/CallScriptView.vue'),
          },
          {
            path: 'contacts',
            name: RouteName.ContactsSettings,
            component: () => import('@/views/settings/ContactsSettingsView.vue'),
          },
          {
            path: 'hotkeys',
            name: RouteName.HotkeysSettings,
            component: () => import('@/views/settings/HotkeysView.vue'),
          },
          {
            path: 'logs',
            name: RouteName.LogsSettings,
            component: () => import('@/views/settings/LogsView.vue'),
          },
        ],
      },
    ],
  },
  {
    path: '/:pathMatch(.*)*',
    redirect: '/client/home',
  },
];

export default routes;

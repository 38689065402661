import { httpGet } from '@/api/http';
import { USERS } from '@/constants/endpoint';
import { useQuery } from '@tanstack/vue-query';
import { useSessionStore } from '@/stores/useSessionStore';
import { computed } from 'vue';
import { mapToUser } from '@/api/useUsers';
import type { AxiosError } from 'axios';
import type { LoggedUser } from '@/stores/useUserStore';
import type { UserDTO } from '@/data/User';
import type { UseQueryReturnType } from '@tanstack/vue-query';

export const LOGGED_IN_USER_QUERY_KEY = 'logged_in_user';

export const useLoggedInUser = (): UseQueryReturnType<LoggedUser, AxiosError<unknown, any>> => {
  const $session = useSessionStore();
  const userUuid = computed(() => $session._session.userUuid);
  const isAuthenticated = computed(() => $session._session.isAuthenticated);

  return useQuery({
    queryKey: [LOGGED_IN_USER_QUERY_KEY],
    queryFn: () => httpGet<UserDTO>(USERS, { params: { user_uuid: userUuid.value } }).then((r) => mapToUser(r)),
    enabled: isAuthenticated,
    retry: 3,
    retryDelay: 3_000,
    refetchInterval: 60_000,
  });
};
